<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>角色列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">

            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="角色名称">
                                <el-input v-model="search.role_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="this.Tool.is_auth('systemset.role.issave')" icon="el-icon-plus" size="medium"
                            type="primary" @click="tosee()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table v-loading="loading" :data="tableData" border size="medium">
                <el-table-column prop="role_name" label="角色名称" width="180"></el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === -1" type="danger">删除</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间"></el-table-column>
                <el-table-column prop="update_time" label="更新时间"></el-table-column>
                <el-table-column prop="editor" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('systemset.role.issave') && scope.row.is_predefine === 0"
                            @click="tosee(scope.row.role_uuid)" size="mini">查看
                        </el-button>
                        <el-button
                            v-if="is_auth('systemset.role.isdel') && scope.row.is_admin === 0 && scope.row.is_predefine === 0"
                            @click="operation_tip(scope.row.role_uuid, scope.row.role_name)" type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '角色列表',
            loading: true,
            issearch: true,
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                role_name: '',
            }
            this.is_search();
        },
        // 搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "systemset.role.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 查看
        tosee(role_uuid = '') {
            if (role_uuid === '') {
                this.$router.push({ path: '/systemset/role/see' })
            } else {
                this.$router.push({ path: '/systemset/role/see', query: { role_uuid: role_uuid } })
            }
        },
        // 操作提示
        operation_tip(role_uuid = '', role_name = '') {
            this.$confirm('删除【' + role_name + '】？', '确认信息', { type: 'error' })
                .then(() => {
                    this.isdel(role_uuid, role_name)
                })
                .catch(() => {
                });
        },
        // 删除
        isdel(role_uuid = '', role_name = '') {
            let postdata = {
                api_name: "systemset.role.isdel",
                token: this.Tool.get_l_cache('token'),
                role_uuid: role_uuid,
                role_name: role_name,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
